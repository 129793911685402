import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage.jsx';
import "bootstrap/dist/css/bootstrap.min.css";
import Contactus from './pages/Conttactus.jsx';

function App() {
  return (
    <>
      <Router> {/* Use BrowserRouter instead of Router */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contactus" element={<Contactus />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
