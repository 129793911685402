import React from "react";
import Footer from "./Footer";
import "./Css/contactus.css";
import Navbar from  './Navbar'
import ContactNavbar from "./ContactNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


const Contactus = () => {
  return (
    <div>
        <ContactNavbar/>
      <div className="contactus_all py-5">
        <div className="contact_us_des pt-5">
          <h2>Let's Connect and Collaborate</h2>
          <p>
            Have questions, need support, or ready to discuss your next big
            idea? We’re here to help! Reach out to us, and let’s build solutions
            that drive success together. Your journey starts with a simple
            message—contact us today!
          </p>
        </div>
        <div className="form_container">
          <form action="" className="mx-auto">
            <div style={{marginBottom:'24px'}}>
              <label htmlFor="name" className="form-label">
              Business Email
              </label>
              <input
                type="text"
                className="form_control_input"
                id="name"
                name="name"
                required
                placeholder="Enter Your Email"
              />
            </div>
            <div style={{marginBottom:'24px'}}>
              <label htmlFor="country" className="form-label">
              Type of Request  
              </label>
            
              <select
                className="form-select"
                id="country"
                name="country"
                placeholder=""
                required
              >
                <option value="">Consulting Request</option>
              </select>
             
            </div>
            <div style={{marginBottom:'24px'}}>
              <label htmlFor="country" className="form-label">
              Product/Services
              </label>
              <select
                className="form-select"
                id="country"
                name="country"
                placeholder=""
                required
              >
                <option value="">Application Modernization</option>
                  {/* <option value="Intern">Intern</option>
                  <option value="Fresher">Fresher</option>
                  <option value="Junior">Junior</option>
                  <option value="Senior">Senior</option> */}
              </select>
            </div>
            <div style={{marginBottom:'24px'}}>
              <label htmlFor="message" className="form-label">
              Comments
              </label>
              <textarea
                className="form-message"
                id="message"
                name="message"
                rows="4"
                required
                style={{ color: 'white' }}
              />
            </div>
            <div style={{marginBottom:'24px'}}>
            <label htmlFor="country" className="form-label">
            Upload Your File
              </label>

            <div className="file-upload-container">
  <div className="upload-content">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M20.6903 8.33333H34.9999C35.9204 8.33333 36.6666 9.07953 36.6666 10V33.3333C36.6666 34.2538 35.9204 35 34.9999 35H4.99992C4.07945 35 3.33325 34.2538 3.33325 33.3333V6.66667C3.33325 5.7462 4.07945 5 4.99992 5H17.3569L20.6903 8.33333ZM21.6666 21.6667H26.6666L19.9999 15L13.3333 21.6667H18.3333V28.3333H21.6666V21.6667Z"
        fill="#A2A2A2"
      />
    </svg>
    <div className="upload-text">
      <p className="browse-files">Browse Files</p>
      <p className="drag-drop-text">Drag and Drop Files Here</p>
    </div>
  </div>
  <input
    type="file"
    className="file-input"
    id="file"
    name="file"
  />
</div>
</div>


            <div className="terms-container">
            <input
  type="checkbox"
  id="terms"
  className="custom-checkbox mt-1"
/>
              <label htmlFor="terms" style={{ width: "90%" }}>
              I agree to the privacy policy and consent to XOPS using my contact details to share marketing tips, updates, and exclusive offers.
              </label>
            </div>
            <button type="button">Submit</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default Contactus;
