import React, { useState } from "react";
import xtlogo from "./Images/xtlogo.png";
import "./Css/Homepage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';


const ContactNavbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <div className="navbar_all container-fluid px-4">
        {/* Mobile menu button */}
        <div className="mobile_menu_icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? (
            <FontAwesomeIcon className="close_btn" icon={faX} />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3 6h18M3 12h18M3 18h18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>

        {/* Logo */}
        <div className="logo_container">
          <a href="/">
            <img src={xtlogo} alt="XT Logo" style={{ width: "40px", height: "40px" }} />
          </a>
        </div>

        {/* Navigation Menu */}
        <ul className={`nav_list ${isMobileMenuOpen ? "open" : ""}`}>
       
        <FontAwesomeIcon  onClick={toggleMobileMenu} className="close_btn"  color="white" icon={faX} />
          <li>
            <a href="/">Home</a>
          </li>
          <li className="d-block d-md-none">
            <a href="/">Solutions</a>
          </li>
          <li className="d-block d-md-none">
            <a href="/">Products</a>
          </li>
          <li >
            <a href="/contactus" style={{color:"#998BE5"}}>Contact</a>
          </li>
        </ul>
        <div className="connav_fade">
        
        </div>
      </div>
  
    </div>
  );
};

export default ContactNavbar;
